import { useEffect, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';

import type {
  RegionGroupListItemType,
  RegionGroupResponse,
  TraceId,
} from 'shared/lib/types';
import { CRUDAction } from 'shared/lib/types';

import {
  useGetRegionGroupsByContractContainerQuery,
  useGetRegionGroupsByContractVersionQuery,
} from 'shared/api/rtkq/regiongroups';

export function useAccrualsRegionGroups(
  contractVersionTraceId?: TraceId,
  contractContainerTraceId?: TraceId,
  inheritedRegionGroups: RegionGroupResponse[] = [],
) {
  const { currentData: databaseRegionGroups } =
    useGetRegionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const { currentData: regionGroupsFromContractContainer } =
    useGetRegionGroupsByContractContainerQuery(
      contractContainerTraceId ?? skipToken,
    );

  const [allRegionGroups, setAllRegionGroups] = useState<
    RegionGroupListItemType[]
  >([]);
  const [defaultRegionGroups, setDefaultRegionGroups] = useState<
    RegionGroupListItemType[]
  >([]);

  useEffect(() => {
    // always prepend the global regionGroup
    if (databaseRegionGroups !== undefined) {
      const newRegionGroups = [
        { name: 'Global (all added regions)', global: true },
      ] as RegionGroupListItemType[];
      setAllRegionGroups([
        ...newRegionGroups,
        ...databaseRegionGroups.map((dbRegionGroup) => ({
          ...dbRegionGroup,
          global: false,
          new: false,
        })),
        ...inheritedRegionGroups.map((regionGroup) => ({
          ...regionGroup,
          trace_id: `${regionGroup.trace_id}_new`, // Force a new trace_id to avoid collisions with existing groups
          global: false,
          new: true,
          action: CRUDAction.CREATE,
        })),
      ]);
    }

    if (
      regionGroupsFromContractContainer !== undefined &&
      databaseRegionGroups?.length === 0
    ) {
      const newDefaultRegionGroups = [
        { name: 'Global (all added regions)', global: true },
      ] as RegionGroupListItemType[];
      setDefaultRegionGroups([
        ...newDefaultRegionGroups,
        ...regionGroupsFromContractContainer.map((ccRegionGroup) => ({
          ...ccRegionGroup,
          global: false,
          new: true,
          action: CRUDAction.CREATE,
        })),
      ]);
    }
  }, [
    databaseRegionGroups,
    regionGroupsFromContractContainer,
    inheritedRegionGroups.length, // Infinite loop if we do inheritedRegionGroups
  ]);

  return { allRegionGroups, setAllRegionGroups, defaultRegionGroups };
}
