import { useMemo } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import usePeriodMenuItemBlob from 'accruals/components/periods/hooks/usePeriodMenuItemBlob';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import {
  type ContractContainerResponse,
  type ContractVersionResponse,
  PeriodMenuItemType,
  type PeriodResponse,
  type PeriodVersionResponse,
  type TraceId,
} from 'shared/lib/types';
import { CRO_CONTRACT_TABS } from 'shared/helpers/helpers';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';
import {
  useGetAipContractVersionQuery,
  useGetContractContainerQuery,
  useGetCurrentContractVersionQuery,
} from 'shared/api/rtkq/contractcontainers';
import { useGetContractWithStatusQuery } from 'shared/api/rtkq/contracts';
import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';

type ReturnValue = {
  activeTab?: string;
  contractVersion?: ContractVersionResponse;
  contractVersionTraceId?: TraceId;
  contractContainer?: ContractContainerResponse;
  contractContainerTraceId?: TraceId;
  period?: PeriodResponse;
  periodTraceId?: TraceId;
  periodVersion?: PeriodVersionResponse;
  periodVersionTraceId?: TraceId;
  contractContainerPeriodMenuItemTraceId?: TraceId;
  contractVersionPeriodMenuItemTraceId?: TraceId;
  isClosedPeriodVersion: boolean;
  loadingContractInformation: boolean;
  liveSlugFromPeriodMenuItem?: string;
};

export default function useContractAndPeriodWithVersions(): ReturnValue {
  const { contractContainerId, contractVersionId, activeTab } = useParams();
  const period = useSelector(selectPeriod);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const isClosedPeriodVersion = useIsClosedPeriodVersion();

  // for open periods
  const contractVersionTraceId = contractVersionId?.startsWith('pmi')
    ? undefined
    : contractVersionId;
  const contractContainerTraceId = contractContainerId?.startsWith('pmi')
    ? undefined
    : contractContainerId;

  // for closed periods
  const contractVersionPeriodMenuItemTraceId = contractVersionId?.startsWith(
    'pmi',
  )
    ? contractVersionId
    : undefined;
  const contractContainerPeriodMenuItemTraceId =
    contractContainerId?.startsWith('pmi') ? contractContainerId : undefined;

  const {
    currentData: contractContainerFromQuery,
    isLoading: contractContainerLoading,
  } = useGetContractContainerQuery(contractContainerTraceId ?? skipToken);
  const {
    currentData: contractVersionFromQuery,
    isLoading: contractVersionLoading,
  } = useGetContractWithStatusQuery(
    contractVersionTraceId
      ? { trace_id: contractVersionTraceId, otherParameter: period.trace_id }
      : skipToken,
  );
  const { data: currentContract } = useGetCurrentContractVersionQuery(
    contractContainerTraceId
      ? { trace_id: contractContainerTraceId, otherParameter: period.trace_id }
      : skipToken,
  );
  const { data: aipContract } = useGetAipContractVersionQuery(
    contractContainerTraceId
      ? { trace_id: contractContainerTraceId, otherParameter: period.trace_id }
      : skipToken,
  );
  const {
    currentData: contractContainerTabsPeriodMenuItems,
    isLoading: contractContainerTabsPeriodMenuItemLoading,
  } = useGetPeriodMenuItemQuery(
    contractContainerPeriodMenuItemTraceId
      ? {
          saved_object_type: PeriodMenuItemType.CRO_CONTRACT_CONTAINER_TABS,
          period_version: currentPeriodVersion.trace_id,
          parent_menu_item: contractContainerPeriodMenuItemTraceId,
        }
      : skipToken,
  );

  const loading =
    contractContainerLoading ||
    contractVersionLoading ||
    contractContainerTabsPeriodMenuItemLoading;

  // there can only be one, but our filter query helper assumes more than one
  const pmiTabs = usePeriodMenuItemBlob(
    contractContainerTabsPeriodMenuItems?.[0],
  );

  return useMemo(() => {
    let contractVersionToReturn;
    if (contractVersionFromQuery) {
      contractVersionToReturn = contractVersionFromQuery;
    } else if (activeTab === CRO_CONTRACT_TABS.AMENDMENT_IN_PROGRESS) {
      contractVersionToReturn = aipContract;
    } else {
      contractVersionToReturn = currentContract;
    }

    let finalContractVersionPeriodMenuItemTraceId;
    if (contractVersionPeriodMenuItemTraceId) {
      finalContractVersionPeriodMenuItemTraceId =
        contractVersionPeriodMenuItemTraceId;
    } else if (activeTab === CRO_CONTRACT_TABS.AMENDMENT_IN_PROGRESS) {
      finalContractVersionPeriodMenuItemTraceId =
        pmiTabs?.amendmentInProgressMenuItem?.trace_id;
    } else {
      finalContractVersionPeriodMenuItemTraceId =
        pmiTabs?.currentContractMenuItem?.trace_id;
    }

    return {
      activeTab,
      contractVersion:
        contractVersionToReturn === null ? undefined : contractVersionToReturn,
      contractVersionTraceId: contractVersionToReturn?.trace_id,
      contractContainer: contractContainerFromQuery,
      contractContainerTraceId: contractContainerFromQuery?.trace_id,
      period,
      periodTraceId: period.trace_id,
      periodVersion: currentPeriodVersion,
      periodVersionTraceId: currentPeriodVersion.trace_id,
      contractContainerPeriodMenuItemTraceId,
      contractVersionPeriodMenuItemTraceId:
        finalContractVersionPeriodMenuItemTraceId,
      loadingContractInformation: loading,
      isClosedPeriodVersion,
      liveSlugFromPeriodMenuItem:
        contractContainerTabsPeriodMenuItems?.[0]?.live_slug,
    };
  }, [
    contractVersionFromQuery,
    activeTab,
    contractVersionPeriodMenuItemTraceId,
    contractContainerFromQuery,
    period,
    currentPeriodVersion,
    contractContainerPeriodMenuItemTraceId,
    loading,
    isClosedPeriodVersion,
    aipContract,
    currentContract,
    pmiTabs?.amendmentInProgressMenuItem?.trace_id,
    pmiTabs?.currentContractMenuItem?.trace_id,
    contractContainerTabsPeriodMenuItems,
  ]);
}
