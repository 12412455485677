import { type ReactElement, useState } from 'react';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { selectCompany } from 'shared/state/slices/companySlice';
import PurchaseOrdersUploadWizard from 'shared/wizards/purchase-order/PurchaseOrdersUploadWizard';

function PurchaseOrdersHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);
  const [showUploadWizard, setShowUploadWizard] = useState<boolean>(false);
  const isIntegrationEnabledForPOListing = useFeatureFlag(
    'integration_po_listing',
  );

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 2,
          gap: 1,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        {isIntegrationEnabledForPOListing && (
          <Button
            startIcon={<ElectricalServicesIcon />}
            sx={{ alignSelf: 'flex-end' }}
            testId="btn_import_invoice_listing"
            variant="contained"
          >
            Import
          </Button>
        )}
        <Button
          startIcon={<UploadIcon />}
          testId="upload_po_listing"
          variant="contained"
          onClick={() => setShowUploadWizard(true)}
        >
          Upload PO Listing
        </Button>
      </Box>
      {showUploadWizard && (
        <PurchaseOrdersUploadWizard
          companyTraceId={currentCompany.trace_id}
          onClose={() => setShowUploadWizard(false)}
        />
      )}
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(PurchaseOrdersHeader);
