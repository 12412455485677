import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  missingForecast,
  selectForecast,
} from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withForecastingPageRequiredData from 'forecasting/api/hocs/withForecastingPageRequiredData';

import AssumptionsTab from './components/AssumptionsTab';
import DetailTab from './components/DetailTab';
import ForecastingTabs from './components/ForecastingTabs';
import SummaryTab from './components/SummaryTab';
import ParametersTab from './components/ParametersTab';

function ForecastingPage() {
  const { activeTab } = useParams<{
    activeTab: 'assumptions' | 'detail' | 'parameters' | 'summary';
  }>();
  const forecast = useSelector(selectForecast);
  const navigate = useNavigate();

  useEffect(() => {
    // If we dont have forecast, but we are still in a tab, assume we should navigate out of forecast details
    if (activeTab && forecast === missingForecast) {
      navigate(routes.getForecast());
    }
    // if we have a forecast but no active tab, assume we should navigate to the summary tab
    else if (!activeTab && forecast !== missingForecast) {
      navigate(routes.getForecastSummaryTab(forecast.trace_id));
    }
  }, [activeTab, forecast, navigate]);

  // we have to wait for the above `useEffect` to do its thing
  if (!activeTab) {
    return null;
  }

  return (
    <PageContentLoadingContainer
      tabs={<ForecastingTabs />}
      sx={{
        p: 0,
        display: 'flex',
        flexFlow: 'column',
        bgcolor: 'common.white',
      }}
    >
      <Box
        sx={{
          p: 4,
          transition: ({ transitions }) =>
            transitions.create(['margin-bottom']),
          ...(activeTab === 'parameters' && {
            minHeight: 0,
            display: 'flex',
            flex: 1,
          }),
        }}
      >
        {activeTab === 'summary' && <SummaryTab />}
        {activeTab === 'detail' && <DetailTab />}
        {activeTab === 'assumptions' && <AssumptionsTab />}
        {activeTab === 'parameters' && <ParametersTab />}
      </Box>
    </PageContentLoadingContainer>
  );
}

export default withForecastingPageRequiredData(ForecastingPage);
