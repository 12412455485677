import type { SxProps } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import { useGetPoCompletenessQuery } from 'shared/api/rtkq/periods';

import usePoCompletenessColumnDefs from './hooks/usePoCompletenessColumnDefs';
import usePoCompletenessGridOptions from './hooks/usePoCompletenessGridOptions';
import usePoCompletenessRows from './hooks/usePoCompletenessRows';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function PoCompletenessGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;
  const period = useSelector(selectPeriod);
  const { currentData: poCompleteness } = useGetPoCompletenessQuery(
    period.trace_id,
  );

  const columnDefs = useGridColDefs(usePoCompletenessColumnDefs);
  const rowData = usePoCompletenessRows(poCompleteness, true);
  const gridOptions = useGridOptions(usePoCompletenessGridOptions);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  PoCompletenessGrid,
  PeriodGridBlobType.PO_COMPLETENESS,
);
