import { useState } from 'react';

import { useSelector } from 'react-redux';

import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import {
  type TraceId,
  UnmappedFileEntityType,
  type UnmappedFileResponse,
  type UploadedFile,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectCompany } from 'shared/state/slices/companySlice';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-csv-step/UploadUnmappedCsvStep';

import {
  useCreateHistoricPurchaseOrdersSnapshotFromCsvMutation,
  useDeleteHistoricPurchaseOrdersSnapshotMutation,
} from 'shared/api/rtkq/historicpurchaseordersnapshots';

import columns from '../purchase-order/columns'; // these always need to match
import HistoricPurchaseOrdersConfirmationStep from './steps/HistoricPurchaseOrdersConfirmationStep';
import HistoricPurchaseOrdersReviewStep from './steps/HistoricPurchaseOrdersReviewStep';

type Props = {
  companyTraceId: TraceId;
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function HistoricPurchaseOrdersUploadWizard(props: Props) {
  const { companyTraceId, onClose: onCloseProp, onComplete } = props;
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [snapshotId, setSnapshotId] = useState<TraceId>();

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteHistoricPurchaseOrdersSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  const currentCompany = useSelector(selectCompany); // use on the company view only; if the period version is available we prefer to get the company from that on the backend to ensure the period version and company are consistent

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(fileResponses: UnmappedFileResponse[]) {
    setUploadedFiles(
      fileResponses.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  const uploadHeader = 'Upload your historic PO listing file.';

  const mapperHeader = 'Assign these headers to your historic PO listing.';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="SCAMANDER"
        startIndex={0}
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Historic PO Listing"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          accept=".csv"
          company={currentCompany}
          entityClass={UnmappedFileEntityType.HistoricPurchaseOrderSnapshot}
          fileTypeWithGrammaticalArticle="a historic PO listing"
          header={uploadHeader}
          setSnapshotId={setSnapshotId}
          titleOverride="Historic PO Listing"
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          extraCreateSnapshotArgs={{
            snapshot: snapshotId ?? '',
            company: companyTraceId,
          }}
          useCreateSnapshotEndpoint={
            useCreateHistoricPurchaseOrdersSnapshotFromCsvMutation
          }
        />
        <HistoricPurchaseOrdersConfirmationStep snapshotId={snapshotId} />
        <HistoricPurchaseOrdersReviewStep
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('PO listing uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default HistoricPurchaseOrdersUploadWizard;
