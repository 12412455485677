import { useSelector } from 'react-redux';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import {
  getForecastAssumptionsTab,
  getForecastDetailTab,
  getForecastParametersTab,
  getForecastSummaryTab,
} from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

function ForecastingTabs() {
  const forecast = useSelector(selectForecast);
  const isParametersTabEnabled = useFeatureFlag('forecasting_parameters_tab');

  const forecastingTabs = [
    { key: getForecastSummaryTab(forecast.trace_id), label: 'Summary' },
    { key: getForecastDetailTab(forecast.trace_id), label: 'Detail' },
    ...(isParametersTabEnabled
      ? [
          {
            key: getForecastParametersTab(forecast.trace_id),
            label: 'Parameters',
          },
        ]
      : [
          {
            key: getForecastAssumptionsTab(forecast.trace_id),
            label: 'Assumptions',
          },
        ]),
  ];

  return <NavigationTabs tabs={forecastingTabs} />;
}

export default ForecastingTabs;
