import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectForecast } from 'forecasting/state/slices/forecastSlice';
import type { ForecastParameterType } from 'shared/lib/types';

import {
  useCreateForecastParameterMutation,
  useUpdateForecastParameterMutation,
} from 'shared/api/rtkq/forecastparameters';

const useForecastParamEditorColDefs = (
  type: ForecastParameterType,
  first_column_header: string,
): CondorColDef[] => {
  const forecast = useSelector(selectForecast);
  const [createForecastParameter] = useCreateForecastParameterMutation();
  const [updateForecastParameter] = useUpdateForecastParameterMutation();

  return useMemo(() => {
    const handleParameterUpsert = (
      parameter_trace_id: string | undefined,
      regionName: string,
      newValue?: number,
    ) => {
      void (async () => {
        if (newValue === undefined) {
          return;
        }
        if (!parameter_trace_id) {
          await createForecastParameter({
            forecast: forecast.trace_id,
            type,
            region_name: regionName,
            value: newValue,
          }).unwrap();
        } else {
          await updateForecastParameter({
            trace_id: parameter_trace_id,
            value: newValue,
          }).unwrap();
        }
      })();
    };

    return [
      {
        field: 'region_name',
        headerName: first_column_header,
      },
      {
        field: 'actual',
        headerName: 'Actual',
      },
      {
        field: 'forecast',
        headerName: 'Forecast',
        ...(!forecast.locked && {
          editable: true,
          onCellValueChanged: (event) => {
            handleParameterUpsert(
              event.data.parameter_trace_id,
              event.data.region_name,
              event.newValue,
            );
          },
        }),
      },
      { field: 'parameter_trace_id', hide: true },
    ];
  }, [
    forecast,
    createForecastParameter,
    updateForecastParameter,
    type,
    first_column_header,
  ]);
};

export default useForecastParamEditorColDefs;
