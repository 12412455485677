import { useMemo } from 'react';

import groupBy from 'lodash/groupBy';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getCommentButtonCellConfig,
  getToggleableMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type {
  CondorColDef,
  CondorColGroupDef,
} from 'shared/components/ag-grid/types';
import useRowCommentsCountLoader from 'shared/components/comments/hooks/useRowCommentsCountLoader';

import {
  getVisitSortIndex,
  pizzaJoin,
} from 'accruals/pages/trial-activity/patient-container/patient-activity/hooks/getPatientActivityColumnDefs';
import type {
  AdministrativeOrProcedureCategoryResponse,
  CommentsCountsGroupedByRowId,
  PatientCohortResponse,
} from 'shared/lib/types';
import { CommentLocationType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useGetAdministrativeOrProcedureCategoriesByTrialQuery } from 'shared/api/rtkq/administrativeorprocedurecategories';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import { getCommentSlugForCroTabs } from '../CroContractContainerPage';

export const processCroInvestigatorFeesExpenseGridColumnDefs = (
  adminOrProcedureCategories:
    | AdministrativeOrProcedureCategoryResponse[]
    | undefined,
  patientCohorts: PatientCohortResponse[] | undefined,
  activeTab: string | undefined,
  contractContainerTraceId: string | undefined,
  commentCountsByRowId: CommentsCountsGroupedByRowId | undefined,
) => {
  const procedureCategories = adminOrProcedureCategories?.filter(
    (category) => category.category_type === 'INV',
  );

  // This is to avoid a collision with the existing `currency` column
  const currencyKeyOverride = {
    cellRendererParams: { currencyKey: 'currency_toggleable' },
  };
  return [
    {
      headerName: '',
      children: [
        { field: 'site_trace_id', hide: true },
        {
          headerName: 'Site #',
          field: 'site_number',
          pinned: 'left',
          filter: true,
        },
        { headerName: 'Site name', field: 'site_name', pinned: 'left' },
        {
          headerName: 'Region',
          field: 'region_name',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: 'Total LTD expense',
          ...getToggleableMoneyCellConfig(
            'total_toggleable',
            currencyKeyOverride,
          ),
          aggFunc: 'sum',
        },
      ],
    },
    {
      headerName: 'Patient Numbers',
      children: [
        { headerName: 'Active', field: 'patients_active', aggFunc: 'sum' },
        {
          headerName: 'Screened',
          field: 'patients_screened',
          aggFunc: 'sum',
        },
        {
          headerName: 'Screen-failed',
          field: 'patients_screen_failed',
          aggFunc: 'sum',
        },
        {
          headerName: 'Enrolled',
          field: 'patients_enrolled',
          aggFunc: 'sum',
        },
        {
          headerName: 'Dropped/completed',
          field: 'patients_dropped_completed',
          aggFunc: 'sum',
        },
      ],
    },
    ...Object.entries(
      groupBy(
        [...(patientCohorts ?? [])].sort((cohortA, cohortB) =>
          cohortA.name.localeCompare(cohortB.name),
        ),
        (cohort) => cohort.pizza_name ?? cohort.name,
      ),
    ).map((cohortGrouping) => ({
      headerName: pizzaJoin(cohortGrouping).toUpperCase(),
      children: Object.entries(
        groupBy(
          cohortGrouping[1].flatMap((cohort) => cohort.patient_assessments),
          (visit) => visit.pizza_name ?? visit.name,
        ),
      )
        .map((visitGrouping) => {
          visitGrouping[1].sort(
            (visitA, visitB) => visitA.order_index - visitB.order_index,
          );
          return visitGrouping;
        })
        .sort(
          (visitGroupingA, visitGroupingB) =>
            getVisitSortIndex(visitGroupingA) -
            getVisitSortIndex(visitGroupingB),
        )
        .map((visitGrouping) => ({
          headerName: pizzaJoin(visitGrouping),
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            `${cohortGrouping[0]}_${visitGrouping[0]}_toggleable`,
            currencyKeyOverride,
          ),
        })),
    })),
    {
      headerName: 'Total',
      children: [
        {
          headerName: 'Expected Visit Costs',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'expected_visit_costs_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Actual Visit Costs',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'actual_visit_costs_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Total Visit Costs',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'final_visit_costs_toggleable',
            currencyKeyOverride,
          ),
        },
      ],
    },

    {
      headerName: 'Admin Costs',
      children: [
        {
          headerName: 'Startup',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'startup_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Close',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'close_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Annual',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'annual_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Quarterly',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'quarterly_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Monthly',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'monthly_toggleable',
            currencyKeyOverride,
          ),
        },
        {
          headerName: 'Total admin costs',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'total_admin_toggleable',
            currencyKeyOverride,
          ),
        },
      ],
    },
    {
      headerName: 'Procedure Costs',
      children: [
        ...Object.entries(
          groupBy(
            procedureCategories,
            (category) => category.mapped_edc_name ?? category.name,
          ),
        )
          .map((categoryGrouping) => {
            categoryGrouping[1].sort((visitA, visitB) =>
              visitA.name.localeCompare(visitB.name),
            );
            return categoryGrouping;
          })
          .sort((categoryGroupingA, categoryGroupingB) =>
            categoryGroupingA[0].localeCompare(categoryGroupingB[0]),
          )
          .map((categoryGrouping) => ({
            headerName: pizzaJoin(categoryGrouping),
            aggFunc: 'sum',
            ...getToggleableMoneyCellConfig(
              `${categoryGrouping[0]}_toggleable`,
              currencyKeyOverride,
            ),
          })),
        {
          headerName: 'Total procedure costs',
          aggFunc: 'sum',
          ...getToggleableMoneyCellConfig(
            'total_procedures_toggleable',
            currencyKeyOverride,
          ),
        },
      ],
    },
    {
      ...getCommentButtonCellConfig(
        CommentLocationType.INVESTIGATOR_FEES_EXPENSE,
        getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
        commentCountsByRowId,
      ),
    },
  ];
};

/** isOpenPeriod is here so this works the same as all the other defs.
    if adding a NON READ ONLY field, please ensure you honor this flag
    else period close might not work correctly */
export default function useCroInvestigatorFeesExpenseGridColumnDefs(
  _isOpenPeriod: boolean,
): Array<CondorColDef | CondorColGroupDef> {
  const trial = useSelector(selectTrial);
  const { currentData: adminOrProcedureCategories } =
    useGetAdministrativeOrProcedureCategoriesByTrialQuery(trial.trace_id);
  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    trial.trace_id,
  );

  const { activeTab } = useParams();
  const { contractContainerTraceId } = useContractAndPeriodWithVersions();
  const commentCountsByRowId = useRowCommentsCountLoader(
    CommentLocationType.INVESTIGATOR_FEES_EXPENSE,
    getCommentSlugForCroTabs(activeTab, contractContainerTraceId),
  );

  return useMemo(
    () =>
      processCroInvestigatorFeesExpenseGridColumnDefs(
        adminOrProcedureCategories,
        patientCohorts,
        activeTab,
        contractContainerTraceId,
        commentCountsByRowId,
      ),
    [
      patientCohorts,
      adminOrProcedureCategories,
      activeTab,
      contractContainerTraceId,
      commentCountsByRowId,
    ],
  );
}
