import { useState } from 'react';

import { useSelector } from 'react-redux';

import ColumnMapperStep from 'shared/lib/column-mapper/ColumnMapperStep';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import {
  UnmappedFileEntityType,
  type UnmappedFileResponse,
  type UploadedFile,
} from 'shared/lib/types';
import Wizard from 'shared/lib/wizard/Wizard';
import { selectCompany } from 'shared/state/slices/companySlice';
import useOnCloseLogic from 'shared/wizards/hooks/useOnCloseLogic';
import UploadUnmappedCsvStep from 'shared/wizards/upload-unmapped-csv-step/UploadUnmappedCsvStep';

import {
  useCreateHistoricInvoiceListingsSnapshotFromCsvMutation,
  useDeleteHistoricInvoiceListingsSnapshotMutation,
} from 'shared/api/rtkq/historicinvoicesnapshots';

import columns from '../invoice-upload-wizard/columns';
import HistoricInvoiceReviewStep from './steps/HistoricInvoiceReviewStep';
import HistoricInvoiceValidationStep from './steps/HistoricInvoiceValidationStep';

type InvoiceUploadWizardProps = {
  onClose: () => void;
  onComplete?: (message: string) => void;
};

function HistoricInvoiceUploadWizard(props: InvoiceUploadWizardProps) {
  const { onClose: onCloseProp, onComplete } = props;
  const company = useSelector(selectCompany);
  const [snapshotId, setSnapshotId] = useState<string>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const [deleteSnapshot, { isLoading: _deleteIsLoading }] =
    useDeleteHistoricInvoiceListingsSnapshotMutation();
  const [setFfMappingId, handleCloseBtnClick] = useOnCloseLogic();

  function onClose(isLastStepSaveClick?: boolean) {
    handleCloseBtnClick(
      uploadedFiles,
      deleteSnapshot,
      setUploadedFiles,
      setSnapshotId,
      onCloseProp,
      isLastStepSaveClick,
      snapshotId,
    );
  }

  function saveUrl(files: UnmappedFileResponse[]) {
    setUploadedFiles(
      files.map((file) => ({
        csvBlobUrl: file.file,
        unmappedFileUrl: file.url,
        traceId: file.trace_id,
      })),
    );
  }

  const uploadHeader = 'Upload your Historic invoice listing file.';

  const mapperHeader = 'Assign these headers to your Historic invoice report';

  return (
    <FullWidthDialog open>
      <Wizard
        devTitle="Historic Invoice"
        stepNames={['Map Columns', 'Validate', 'Review']}
        title="Historic Invoice Upload"
        onClose={onClose}
      >
        <UploadUnmappedCsvStep
          company={company}
          entityClass={UnmappedFileEntityType.HistoricInvoiceSnapshot}
          fileTypeWithGrammaticalArticle="a historic invoice report"
          header={uploadHeader}
          setSnapshotId={setSnapshotId}
          titleOverride="Historic Invoice Listing"
          onSave={saveUrl}
        />
        <ColumnMapperStep
          columns={columns}
          extraCreateSnapshotArgs={{ company: company.trace_id }}
          setFfMappingId={setFfMappingId}
          setSnapshotId={setSnapshotId}
          stepTitleText={mapperHeader}
          uploadedFiles={uploadedFiles}
          useCreateSnapshotEndpoint={
            useCreateHistoricInvoiceListingsSnapshotFromCsvMutation
          }
        />
        <HistoricInvoiceValidationStep snapshotId={snapshotId} />
        <HistoricInvoiceReviewStep
          snapshotId={snapshotId}
          onComplete={() =>
            onComplete
              ? onComplete('Historic Invoice Report uploaded successfully')
              : onCloseProp()
          }
        />
      </Wizard>
    </FullWidthDialog>
  );
}

export default HistoricInvoiceUploadWizard;
