import type {
  AuditLogGridResponse,
  CompanyFilesGridRow,
  CompanyResponse,
  ContentTypeResponse,
  ControlListingTaskResponse,
  CurrencyCode,
  InvoiceRecordResponse,
  PurchaseOrderRecordResponse,
  ThirdPartyCompanyOptions,
  UserRow,
} from 'shared/lib/types';

import constructApi from './constructApi';
import { CONTROL_LISTING_TASKS } from './controllistingtasks';
import { FX_RATES } from './fxrates';
import { HISTORIC_INVOICE_LISTING_RECORDS_TAG } from './historicinvoicerecords';
import { HISTORIC_PURCHASE_ORDER_RECORDS_TAG } from './historicpurchaseorderrecords';
import { PURCHASE_ORDER_RECORDS_TAG } from './purchaseorderrecords';
import { USERS_TAG } from './users';

export const LATEST_INVOICE_SNAPSHOT_RECORD = 'LATEST_INVOICE_SNAPSHOT_RECORD';

export const COMPANY_FILES = 'COMPANY_FILES';
export const COMPANIES_TAG = 'COMPANIES';
export const GL_ACCOUNT_GRID_TAG = 'GL_ACCOUNT_GRID';
export const COMPANY_OTHER_TAGS = [
  LATEST_INVOICE_SNAPSHOT_RECORD,
  PURCHASE_ORDER_RECORDS_TAG,
  COMPANY_FILES,
  CONTROL_LISTING_TASKS,
  'CONTENT_TYPE',
  GL_ACCOUNT_GRID_TAG,
];

const apiEndpointFactory = constructApi<CompanyResponse>('companies').withTags(
  COMPANIES_TAG,
  COMPANY_OTHER_TAGS,
);
export const api = apiEndpointFactory.inject(() => ({
  getCompanies: apiEndpointFactory.getAll(),
  getCompanyFiles: apiEndpointFactory.getExtraRoute<{
    rows: CompanyFilesGridRow[];
  }>('company_files', [COMPANY_FILES]),
  getLatestHistoricInvoiceSnapshotRecordsByCompany:
    apiEndpointFactory.getExtraRoute<InvoiceRecordResponse[]>(
      'get_latest_historic_invoice_snapshot_records',
      [HISTORIC_INVOICE_LISTING_RECORDS_TAG],
    ),
  getLatestInvoiceSnapshotRecordsByCompany: apiEndpointFactory.getExtraRoute<
    InvoiceRecordResponse[]
  >('get_latest_invoice_snapshot_records', [LATEST_INVOICE_SNAPSHOT_RECORD]),
  updateCompany: apiEndpointFactory.update(),
  getLatestHistoricPurchaseOrderSnapshotRecordsByCompany:
    apiEndpointFactory.getExtraRoute<PurchaseOrderRecordResponse[]>(
      'get_latest_historic_po_snapshot_records',
      [HISTORIC_PURCHASE_ORDER_RECORDS_TAG],
    ),
  getLatestPurchaseOrderSnapshotRecordsByCompany:
    apiEndpointFactory.getExtraRoute<PurchaseOrderRecordResponse[]>(
      'get_latest_po_snapshot_records',
      [PURCHASE_ORDER_RECORDS_TAG],
    ),
  getControlListTasks: apiEndpointFactory.getExtraRoute<
    ControlListingTaskResponse[]
  >('control_listing_tasks', [CONTROL_LISTING_TASKS]),
  getUsersByCompany: apiEndpointFactory.getExtraRoute<UserRow[]>(
    'get_users_by_company',
    [USERS_TAG],
  ),
  getThirdPartyCompaniesByCompany: apiEndpointFactory.getExtraRoute<
    ThirdPartyCompanyOptions[]
  >('get_third_party_companies', [USERS_TAG]),
  getRelevantFxCurrencies: apiEndpointFactory.getExtraRoute<
    Record<CurrencyCode, CurrencyCode[]>
  >('relevant_fx_currencies', [FX_RATES]),
  getAuditLog:
    apiEndpointFactory.getExtraRouteTwoParametersWithFiltersAndPaging<AuditLogGridResponse>(
      'get_audit_log',
    ),
  getContentTypes: apiEndpointFactory.getExtraRouteTwoParameters<
    ContentTypeResponse[]
  >('get_content_types', ['CONTENT_TYPE']),
  getGlAccountGrid: apiEndpointFactory.getExtraRoute('gl_account_grid', [
    GL_ACCOUNT_GRID_TAG,
  ]),
}));

export const {
  useGetCompaniesQuery,
  useGetLatestHistoricInvoiceSnapshotRecordsByCompanyQuery,
  useGetLatestInvoiceSnapshotRecordsByCompanyQuery,
  useGetCompanyFilesQuery,
  useGetContentTypesQuery,
  useLazyGetAuditLogQuery,
  useLazyGetCompanyFilesQuery,
  useLazyGetLatestInvoiceSnapshotRecordsByCompanyQuery,
  useUpdateCompanyMutation,
  useGetLatestPurchaseOrderSnapshotRecordsByCompanyQuery,
  useGetLatestHistoricPurchaseOrderSnapshotRecordsByCompanyQuery,
  useGetControlListTasksQuery,
  useLazyGetControlListTasksQuery,
  useGetUsersByCompanyQuery,
  useLazyGetUsersByCompanyQuery,
  useGetThirdPartyCompaniesByCompanyQuery,
  useGetRelevantFxCurrenciesQuery,
  useLazyGetRelevantFxCurrenciesQuery,
  useGetGlAccountGridQuery,
  useLazyGetGlAccountGridQuery,
} = api;
