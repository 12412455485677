import { useMemo, useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ParametersMenu from 'forecasting/components/parameters-menu/ParametersMenu';

import PatientEnrollmentParamsGrid from '../grids/PatientEnrollmentParamsGrid';
import SiteActivationParamsGrid from '../grids/SiteActivationParamsGrid';

const TARGET_SCROLL_SELECTOR = 'data-scroll-to-category';

function ParametersTab() {
  const containerRef = useRef<HTMLElement | null>(null);

  function onItemClick(index: number) {
    const containerOffset =
      containerRef.current?.getBoundingClientRect().top ?? 0;
    const target = containerRef.current?.querySelectorAll(
      `[${TARGET_SCROLL_SELECTOR}]`,
    )[index];

    if (target) {
      containerRef.current?.scrollTo({
        top: (target as HTMLElement).offsetTop - containerOffset,
        behavior: 'smooth',
      });
    }
  }

  return (
    <>
      <ParametersMenu onItemClick={onItemClick} />
      <Box
        ref={containerRef}
        sx={{
          ml: 3,
          overflowY: 'scroll',
          flex: 1,
        }}
      >
        <Typography
          color="primary.main"
          variant="h4"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Scenario planning
        </Typography>
        <Typography
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Patient enrollment
        </Typography>
        <PatientEnrollmentParamsGrid
          sx={useMemo(() => ({ mt: 2, height: '250px' }), [])}
        />
        <Typography
          height={200}
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Patients dropped
        </Typography>
        <Typography
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Site activation
        </Typography>
        <SiteActivationParamsGrid
          sx={useMemo(() => ({ mt: 2, height: '250px' }), [])}
        />
        <Typography
          height={200}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Trial averages
        </Typography>

        <Typography
          color="primary.main"
          variant="h4"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Contract assumptions
        </Typography>
        <Typography
          height={200}
          mt={4}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Patients
        </Typography>
        <Typography
          height={200}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Patient months
        </Typography>
        <Typography
          height={200}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Sites
        </Typography>
        <Typography
          height={200}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Site months
        </Typography>
        <Typography
          height={200}
          variant="subtitle1"
          {...{ [TARGET_SCROLL_SELECTOR]: true }}
        >
          Timeline
        </Typography>
      </Box>
    </>
  );
}

export default ParametersTab;
