import { useMemo } from 'react';

import type { NewValueParams } from '@ag-grid-community/core/dist/types/src/entities/colDef';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';

import {
  getGenericCellConfig,
  getNativeCurrencyMoneyCellConfig,
  getPercentCellConfig,
  getTrialMoneyCellConfig,
  makeEditableIf,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';
import { useGainLossEnabled } from 'shared/components/fx-rate/hooks/useFxRateColumnDefs';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { type AccrualsGridRow, INVOICED } from 'shared/lib/types';

import { useUpsertManualAccrualsDataMutation } from 'shared/api/rtkq/manualaccrualsdata';

export default function useAccrualsColumnDefs(
  isOpenPeriod: boolean,
  isGridLocked?: boolean,
): Array<CondorColDef<AccrualsGridRow>> {
  const period = useSelector(selectPeriod);
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const showGainLoss = useGainLossEnabled();
  const [upsertManualAccrualsData] = useUpsertManualAccrualsDataMutation();
  const themeMode = useTheme().palette.mode;

  const canEditData = isOpenPeriod && canEditTrialInfo && !isGridLocked;

  return useMemo(() => {
    const handleManualAccrualsDataChange = async (event: NewValueParams) => {
      await upsertManualAccrualsData({
        period: period.trace_id,
        contract_container: event.data.contract_container,
        gl_account: event.data.gl_account,
        long_term_prepaid: event.newValue,
      });
    };

    const baseColumns = [
      { headerName: 'PO #s', field: 'po_numbers', ...getGenericCellConfig() },
      { headerName: 'G/L account', field: 'gl_account_number' },
      { headerName: 'Vendor', field: 'vendor_name', aggFunc: 'totalLabel' },
      { headerName: 'Vendor type', width: 50, field: 'vendor_type' },
      {
        headerName: 'Cost categories',
        width: 350,
        field: 'cost_categories',
        ...getGenericCellConfig({ useEmDashInTotal: false }),
      },
      {
        headerName: 'Contract value (Native)',
        field: 'native_contract_value',
        ...getNativeCurrencyMoneyCellConfig(),
        cellRendererSelector: 'currentContractValueRendererSelector',
      },
      {
        headerName: 'Contract value (Trial)',
        field: 'default_contract_value',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
    ];
    const getAsOfPeriodEndColumns = (currencyViewMode: 'native' | 'trial') => {
      const finalPrefix = currencyViewMode === 'native' ? 'native_' : '';
      const finalConfig =
        currencyViewMode === 'native'
          ? getNativeCurrencyMoneyCellConfig
          : getTrialMoneyCellConfig;

      return [
        { headerName: 'Classification status', field: 'classification_status' },
        {
          headerName: 'Unadjusted Dr/Cr Balance',
          field: `${finalPrefix}unadjusted_balance`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'native'
          ? {
              headerName: 'Unadjusted Dr/Cr Balance (Trial Currency)',
              field: 'trial_unadjusted_balance_via_native',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
        {
          headerName: 'Balance in A/P',
          field: `${finalPrefix}balance_in_ap`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'native'
          ? {
              headerName: 'Balance in A/P (Trial Currency)',
              field: 'trial_balance_in_ap_via_native',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
        {
          headerName: 'Balance sheet net-down',
          field: `${finalPrefix}balance_sheet_netdown`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'native'
          ? {
              headerName: 'Balance sheet net-down (Trial Currency)',
              field: 'trial_balance_sheet_netdown_via_native',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
        {
          headerName: 'Accrued expense',
          field: `${finalPrefix}accrued_expense`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'native'
          ? {
              headerName: 'Accrued expense (Trial Currency)',
              field: 'trial_accrued_expense_via_native',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
        {
          headerName: 'Short-term prepaid',
          field: `${finalPrefix}short_term_prepaid`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'native'
          ? {
              headerName: 'Short-term prepaid (Trial Currency)',
              field: 'trial_short_term_prepaid_via_native',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
        currencyViewMode === 'trial'
          ? {
              headerName: 'Long-term prepaid',
              field: 'long_term_prepaid',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
              ...(canEditData && {
                ...makeEditableIf(
                  ({ data }) => data && 'long_term_prepaid' in data,
                  themeMode,
                ),
                onCellValueChanged: handleManualAccrualsDataChange,
              }),
            }
          : null,
        currencyViewMode === 'trial'
          ? {
              headerName: 'Unrealized gain/loss',
              field: 'unrealized_gain_loss',
              aggFunc: 'sum',
              ...getTrialMoneyCellConfig(),
            }
          : null,
      ].filter((item) => item != null);
    };

    const getTotalLtdColumns = (currencyViewMode: 'native' | 'trial') => {
      const finalPrefix = currencyViewMode === 'native' ? 'native_' : '';
      const finalConfig =
        currencyViewMode === 'native'
          ? getNativeCurrencyMoneyCellConfig
          : getTrialMoneyCellConfig;

      return [
        {
          headerName: 'Invoiced',
          field: `${finalPrefix}${INVOICED}`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        {
          headerName: 'Paid',
          field: `${finalPrefix}paid`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        {
          headerName: 'Reconciled expense',
          field: `${finalPrefix}reconciled_expense`,
          aggFunc: 'sum',
          ...finalConfig(),
        },
        currencyViewMode === 'trial'
          ? {
              headerName: '% recognized',
              field: `${finalPrefix}percent_recognized`,
              aggFunc: 'percentRecognized',
              ...getPercentCellConfig({
                useEmDash: false,
                useEmDashInGroup: false,
                useEmDashInTotal: false,
              }),
            }
          : null,
      ].filter((item) => item != null);
    };

    if (showGainLoss) {
      return [
        { headerName: '', children: baseColumns },
        {
          headerName: 'PNL',
          children: [
            { headerName: 'Total LTD', children: getTotalLtdColumns('trial') },
            {
              headerName: 'As of period end',
              children: getAsOfPeriodEndColumns('trial'),
            },
          ],
        },
        {
          headerName: 'Balance Sheet',
          children: [
            { headerName: 'Total LTD', children: getTotalLtdColumns('native') },
            {
              headerName: 'As of period end',
              children: getAsOfPeriodEndColumns('native'),
            },
          ],
        },
      ];
    }

    return [
      { headerName: '', children: baseColumns },
      { headerName: 'Total LTD', children: getTotalLtdColumns('trial') },
      {
        headerName: 'As of period end',
        children: getAsOfPeriodEndColumns('trial'),
      },
    ];
  }, [canEditData, period, showGainLoss, themeMode, upsertManualAccrualsData]);
}
