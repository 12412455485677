import { type ForwardedRef, type ReactNode, forwardRef } from 'react';

import type { AgGridReactProps } from '@ag-grid-community/react';
import type { SxProps } from '@mui/material/styles';

import type {
  AgGridRef,
  UserDisplayOptions,
} from 'shared/components/ag-grid/CondorAgGrid';

// eslint-disable-next-line import/no-restricted-paths -- this is shared between company and accruals
import PeriodGridBlobGrid from 'accruals/components/periods/PeriodGridBlobGrid';

import type { PeriodGridBlobType, TraceId } from 'shared/lib/types';
import { PERIOD_AWARE_GRID_BLOB_TYPES } from 'shared/lib/types';
import UnderConstructionClosedGrid from 'shared/under-construction/UnderConstructionClosedGrid';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';

type GenericAgGridProps<TData = unknown> = AgGridReactProps<TData> & {
  parentMenuItem?: TraceId;
  userDisplayOptions?: UserDisplayOptions;
  sx?: SxProps;
};

export default function withPeriodSpecificGridWrapper<T extends object>(
  LiveDataComponent: (props: T) => JSX.Element | ReactNode,
  savedObjectType: PeriodGridBlobType,
) {
  function PeriodGridWrapper(
    props: GenericAgGridProps & T,
    ref: ForwardedRef<AgGridRef<any>>, // eslint-disable-line @typescript-eslint/no-explicit-any -- this is a generic component
  ) {
    const { parentMenuItem, overlayNoRowsTemplate, userDisplayOptions, sx } =
      props;
    const isClosed = useIsClosedPeriodVersion();

    return isClosed ? (
      PERIOD_AWARE_GRID_BLOB_TYPES.includes(savedObjectType) ? (
        <PeriodGridBlobGrid
          ref={ref}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          parentMenuItem={parentMenuItem}
          savedObjectType={savedObjectType}
          sx={sx}
          userDisplayOptions={userDisplayOptions}
        />
      ) : (
        <UnderConstructionClosedGrid />
      )
    ) : (
      <LiveDataComponent {...props} ref={ref} />
    );
  }

  return forwardRef(PeriodGridWrapper);
}
